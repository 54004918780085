#root {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.customModal {
  background: #212121;
  opacity: 0.2;
  width: 100%;
  padding: 1.5em;
  text-align: end;
  max-width: 1200px !important;
  margin: 0px !important;
  position: fixed;
  bottom: 0%;
  left: 0%;
  z-index: 12000;
}

.customModalAR {
  background: rgba(33, 33, 33, 0.5);
  opacity: 0.2;
  width: 100vw;
  padding: 1.5em;
  text-align: end;
  max-width: 1200px !important;
  margin: 0px !important;
  position: fixed;
  bottom: 0%;
  left: 0%;
  z-index: 12000;
}

.customOverlayAR {
  background: rgba(255 255, 255, 0);
}

.standardModal {
  background: #212121;
  opacity: 0.2;
  width: 60%;
  padding: 1.5em;
}

.menu {
  background: #212121;
  opacity: 0.2;
  width: 100%;
  padding: 0.1em;
  padding-top: 4em;
  text-align: left;
  max-width: 1200px !important;
  margin: 0px !important;
  bottom: 0%;
  left: 0%;
  position: fixed;
  height: 100%;
}

.orientation-overlay {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #1d2630;
  color: white;
  z-index: 9999;
}

.filters {
  background: #1d2630;
  opacity: 0.2;
  width: 100%;
  padding: 0.1em;
  padding-top: 4em;
  padding-bottom: 1em;
  text-align: left;
  max-width: 1200px !important;
  margin: 0px !important;
  bottom: 0%;
  left: 0%;
  position: fixed;
  height: 100%;
}

menu,
ul {
  list-style: none;
  width: 100%;
}

li {
  flex-grow: 1;
}

button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #fff;
  font-size: 2rem;
  text-align: left;
  margin-top: 1rem;
  font-weight: 600;
}

.btn-ar {
  background-color: #ff1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.btn-deselect {
  background-color: gray;
}

.btn-ar-big {
  background-color: #ff1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.btn-upper-left {
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 1000;
}

.btn-lower-left {
  position: absolute;
  display: flex;
  align-items: baseline;
  gap: 12px;
  bottom: 2rem;
  left: 2rem;
  z-index: 1000;
}

.btn-lower-right {
  position: absolute;
  display: flex;
  align-items: baseline;
  gap: 12px;
  bottom: 8px;
  z-index: 1000;
}

.sidebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 0;
  right: 0;
  height: 100vh;
  min-width: 100px;
  z-index: 1000;
  margin-right: 25px;
}

.xr-button {
  background-color: #ff1e1e;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  height: 20px;
  width: 270px;
}

.tutorial-button {
  background-color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  height: 20px;
}

address {
  text-decoration: none;
  font-style: normal;
}

.rot-icon {
  transform: rotate(-90deg);
  margin-left: 7px;
}

.remove-overflow-x {
  overflow-x: hidden !important;
}
